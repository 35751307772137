<template>
  <div class="kecheng">
    <div class="dis_flex_between">
      <div class="btnBox">
        <div class="btnItem" :class="search.IsPurchase == 1 ? 'on' : ''" @click="btnClick(1)">
          套餐赠送
        </div>
        <el-divider direction="vertical" />
        <div class="btnItem" :class="search.IsPurchase == 0 ? 'on' : ''" @click="btnClick(0)">
          单独购买
        </div>
      </div>
      <el-radio-group v-show="search.IsPurchase == 1" v-model="search.isSingle" class="redRadio" @change="searchClick">
        <el-radio :label="0">资料包</el-radio>
        <el-radio :label="1">资料</el-radio>
      </el-radio-group>
    </div>
    <!-- 学习资料 -->
    <div class="content">
      <!-- 学习资料 -->
      <div v-if="list && list.length > 0" class="list-two">
        <div v-for="(item, index) in list" :key="index" class="item">
          <img v-show="search.isSingle == 0" class="item-img" src="@/assets/img/study/fileIcon.png">
          <div v-show="search.isSingle == 1" class="itemImgBox">
            <img v-if="item.filetype == 'excel'" class="imgItem" src="@/assets/img/study/dataXls.png">
            <img v-else-if="item.filetype == 'word'" class="imgItem" src="@/assets/img/study/dataDoc.png">
            <img v-else-if="item.filetype == 'pdf'" class="imgItem" src="@/assets/img/study/dataPdf.png">
            <img v-else-if="item.filetype == 'ppt'" class="imgItem" src="@/assets/img/study/dataPpt.png">
            <img v-else class="imgItem" src="@/assets/img/study/dataFile.png">
          </div>
          <div class="item-content">
            <div class="item-text twoEllipsis">{{ item.name }}</div>
            <div v-show="search.isSingle == 0" class="item-num">
              共{{ item.num ? item.num : 0 }}份资料
            </div>
            <div class="item-size">{{ item.size }}</div>
          </div>
          <div v-show="search.isSingle == 1" class="btn fles-shrink" @click="openKowledge(item)">
            知识点
          </div>
          <div style="height: auto;" class="keep-learn fles-shrink" @click="toMaterialPage(item)">
            查看资料
          </div>
        </div>
      </div>
      <!-- 暂无课程 -->
      <div v-if="!list || list.length == 0" class="nothing">
        <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
        <div class="nothing-text">暂无内容哦~</div>
      </div>
      <el-pagination class="pagination" :total="total" :current-page="pageNum" :page-sizes="[8, 16, 24, 40]"
        :page-size="pageSize" :pager-count="5" background layout="total, sizes, prev, pager, next"
        @current-change="handleCurrentChange" @size-change="handleSizeChange" />
      <el-dialog :visible.sync="kowledgePointsVisible" width="50%" :before-close="kowledgePointsClose"
        :show-close="false">
        <div class="kowledgePoints_wrap">
          <kowledgePoints ref="kowledgePoints" type="4" />
          <div class="determine_btn" @click="kowledgePointsClose">确定</div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { DataClient } from '@/api/dataClient'
const dataClient = new DataClient()
import { getInfo } from '@/api/cookies'
import kowledgePoints from '@/components/kowledgePoints.vue'

export default {
  components: { kowledgePoints },

  data() {
    return {
      list: {},
      search: {
        IsPurchase: 1, // 1 赠送  0 购买
        isSingle: 0 // 0资料包 1 资料
      },
      pageSize: 8,
      pageNum: 1,
      total: 0,
      userInfo: null,
      kowledgePointsVisible: false
    }
  },
  created() {
    this.userInfo = getInfo()
    this.getList()
  },
  methods: {
    // 获取知识套餐列表
    getList() {
      if (this.search.IsPurchase == 1) {
        this.getMyClassDocPackageList()
      } else {
        dataClient
          .getMyDocPackageList(
            this.pageSize,
            this.pageNum,
            this.search.type ? this.search.type : undefined,
            this.userInfo ? this.userInfo.id : undefined
          )
          .then((res) => {
            this.list = res.rows
            this.total = res.total
            if (res.code) {
              this.list = []
              this.total = 0
            }
          })
      }
    },
    /* 获取赠送的资料/资料包 */
    getMyClassDocPackageList() {
      const that = this
      dataClient
        .getMyClassDocPackageList(
          this.pageSize,
          this.pageNum,
          this.search.type ? this.search.type : undefined,
          this.userInfo ? this.userInfo.id : undefined,
          this.search.isSingle
        )
        .then((res) => {
          if (this.search.isSingle == 1) {
            for (const item of res.rows) {
              item.filetype = Vue.prototype.getFileType(item.names)
              Vue.prototype.getFilesize(item.names, function (size) {
                const sizeVal = (size / 1028).toFixed(0) + 'k'
                const data = (size / 1028 / 1024).toFixed(0)
                item.size = data > 0 ? data + 'M' : sizeVal
                that.$forceUpdate()
              })
            }
          }
          this.list = res.rows
          this.total = res.total
          if (res.code) {
            this.list = []
            this.total = 0
          }
        })
    },

    openKowledge(item) {
      this.kowledgePointsVisible = true
      this.$nextTick(() => {
        this.$refs.kowledgePoints.getData(item.coursesId)
      })
    },
    kowledgePointsClose() {
      this.kowledgePointsVisible = false
    },

    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    /* ----------------------------------------------------- */
    isOk(val) {
      this.search = val
      this.getList()
    },
    // 点击图片跳转到详情页
    checkClick(item) {
      this.$router.push(`/data/dataPackageDetail?packageId=${item.coursesId}`)
    },
    /* 赠送/购买筛选 */
    btnClick(val) {
      this.pageNum = 1
      this.list = []
      this.total = 0
      this.search.IsPurchase = val
      this.search.isSingle = 0
      this.getList()
    },
    /* 资料/资料包筛选 */
    searchClick() {
      this.pageNum = 1
      this.list = []
      this.total = 0
      this.getList()
    },
    /* 点击查看资料 */
    toMaterialPage(item) {
      if (this.search.isSingle == 0) {
        this.$router.push({
          path: `/data/materialPage`,
          query: {
            id: item.coursesId,
            title: item.name,
            isPurchase: 0
          }
        })
      } else {
        const data = {
          zlId: item.mid,
          xmId: item.kind,
          linkLocation: item.names,
          type: 1
        }
        dataClient.downloadRecord(data)
        this.$router.push({
          path: '/VIP/sourcePage',
          query: {
            seeNum: item.seeNum,
            names: item.names,
            id: this.id,
            title: this.title,
            schoolId: this.schoolId,
            type: 2,
            IsPurchase: this.IsPurchase != 1
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.kecheng {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  background: #ffffff;
  padding-bottom: 20px;

  .dis_flex_between {
    padding: 24px 32px;
    background: #fff;

    .btnBox {
      display: flex;
      align-items: center;
      color: #666666;
      font-size: 14px;

      .btnItem {
        cursor: pointer;
      }

      .on {
        color: #ff5e51;
      }
    }
  }

  .content {
    min-height: calc(100% - 124px - 27px);
    overflow-y: scroll;

    .list-two {
      padding: 0px 32px;
    }

    .item {
      border-radius: 0px;
      border-bottom: 1px solid #eee;
      margin-bottom: 0px;

      .itemImgBox {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        .imgItem {
          width: 40px;
          height: 40px;
          margin-right: 12px;
        }
      }

      .btn {
        color: #666666;
        border: 1px solid #666666;
        border-radius: 4px;
        font-size: 14px;
        padding: 0 16px;
        line-height: 28px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }
}

.list-two {
  padding: 0px;
  background: #fff;
}

.item-size {
  font-size: 12px;
}

.kowledgePoints_wrap {
  padding-bottom: 20px;

  .determine_btn {
    width: 40%;
    height: 40px;
    margin: 0 auto;
    margin-top: 20px;
    background-color: #ff5e51;
    color: #ffffff;
    font-weight: 500;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
}
</style>
